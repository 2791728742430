<template>
  <div class="Exhibitionprocess">
    <div class="nav" style="height: 100%;width: 100%;">
      <Carousel show="1" />
    </div>
    <div class="content">
        正在建设中
    </div>
    <div class="bot">
      <Bottom />
    </div>
  </div>
</template>

<script>
import Carousel from "../../components/pc-carousel.vue";
import Bottom from "../../components/bottom.vue";
export default {
  components: {
    Carousel,
    Bottom,
  },
};
</script>

<style scoped>
.Exhibitionprocess{
    width: 100%;
    height: 100%;
}
.content{
    width: 100%;
    height: 100px;
}
</style>